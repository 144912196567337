<template>
<div></div>

  
  </template>
  
  <script>
  import { isAuthenticated } from '@/helpers/authHelper';
  
  export default {
    name: 'FooterBar',
    data() {
      return {
        isUserAuthenticated: isAuthenticated()
      };
    },
    watch: {
      $route: {
        immediate: true,
        handler() {
          this.isUserAuthenticated = isAuthenticated();
        }
      }
    }
  };
  </script>
  
  <style scoped>
  /* Your custom styling here */
  </style>
  