<template>
  <div v-if="loading" class="h-screen flex items-center justify-center">
    <i class="fas fa-spinner fa-spin text-2xl"></i>
  </div>
  <div v-if="error" class="h-screen flex items-center justify-center error">
    {{ error }}
  </div>
  <div
    v-if="!loading && !error"
    class="w-full h-full flex flex-col items-center justify-between px-4 pb-20"
  >
    <ToastContainer />

    <p v-if="!currentComponent" class="text-start w-full max-w-4xl py-8">
      {{ typewriterText }}
    </p>

    <!-- dynamic component rendering -->
    <component
      :is="currentComponent"
      v-if="currentComponent && !isLink(selectedBox)"
      :propsPropertyData="propertyData"
      @update:isLoading="handleLoading"
    />

    <!-- always present -->
    <div
      :class="[
        selectedBox === 'Rental Appraisal' ||
        selectedBox === 'Demographics of area' ||
        selectedBox === 'Property feature expert'
          ? 'bottom-14 py-2'
          : 'bottom-0 py-8',
        'z-10 w-full fixed left-0 right-0 bg-white px-4 flex items-center justify-center',
      ]"
    >
      <div
        class="overflow-x-auto whitespace-nowrap white-textarea"
        ref="slider"
      >
        <div
          v-for="(box, index) in boxes"
          :key="index"
          class="bg-neutral-100 rounded-xl flex-shrink-0 inline-flex flex-col items-center hover:bg-neutral-200 cursor-pointer mr-4 font-bold py-2.5 px-4"
          :class="[{ 'selected-box': box.title === selectedBox }]"
          @click="handleBoxClick(box)"
        >
          <div class="text-start relative">
            <p
              v-if="!isLoading || box.title !== selectedBox"
              class="text-xss m-0"
            >
              {{ box.title }}
              <br />
              <span class="text-gray-500">{{ box.text }}</span>
              <i v-if="box.status === 'lock'" class="fas fa-lock ml-2"></i>
            </p>
            <i
              v-if="isLoading && box.title === selectedBox"
              class="fa-solid fa-spinner animate-spin"
            ></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from "vue";
import { useToast, ToastContainer } from "vue-toastification";
import "vue-toastification/dist/index.css";
import QuickSearch from "@/components/QuickSearch.vue";
import RentalAppraisal from "@/components/RentalAppraisal.vue";
import TranslateText from "@/components/TranslateText.vue";
import DemographicsArea from "@/components/DemographicsArea.vue";
import PropertyFeatureExpert from "@/components/PropertyFeatureExpert.vue";

export default {
  name: "SandyPage",
  components: {
    QuickSearch,
    RentalAppraisal,
    TranslateText,
    DemographicsArea,
    PropertyFeatureExpert
  },
  setup() {
    const toast = useToast();
    const loading = ref(false);
    const error = ref(null);
    const typewriterText = ref("");
    const typewriterMessage = "What can I help you with?";

    const boxes = ref([
      {
        title: "Rental Appraisal",
        text: "create a rental appraisal letter",
        type: "component",
      },
      {
        title: "Translate",
        text: "translate to any language",
        type: "component",
      },
      {
        title: "Demographics of area",
        text: "search through the demographics of the area",
        type: "component",
      },
      {
        title: "Property feature expert",
        text: "take a photo, become an expert in seconds",
        type: "component",
      },
    ]);

    const componentsMap = {
      "Rental Appraisal": "RentalAppraisal",
      Translate: "TranslateText",
      "Demographics of area": "DemographicsArea",
      "Property feature expert":"PropertyFeatureExpert"
    };

    const selectedBox = ref("");
    const isLoading = ref(false);
    const handleLoading = (loading) => {
      isLoading.value = loading;
    };

    const currentComponent = computed(() =>
      selectedBox.value &&
      boxes.value.find((box) => box.title === selectedBox.value)?.type ===
        "component"
        ? componentsMap[selectedBox.value]
        : null
    );

    const slider = ref(null);

    const handleBoxClick = (box) => {
      selectedBox.value = box.title;
      if (box.type === "link") {
        window.location.href = componentsMap[box.title];
      }
    };

    const isLink = (boxTitle) => {
      const box = boxes.value.find((box) => box.title === boxTitle);
      return box?.type === "link";
    };

    const typewriterEffect = (text, index = 0) => {
      if (index < text.length) {
        typewriterText.value += text.charAt(index);
        setTimeout(() => {
          typewriterEffect(text, index + 1);
        }, 50); // Adjust the speed by changing the delay time
      }
    };

    onMounted(() => {
      typewriterEffect(typewriterMessage);
    });

    return {
      loading,
      error,
      ToastContainer,
      toast,
      boxes,
      selectedBox,
      isLoading,
      handleBoxClick,
      currentComponent,
      handleLoading,
      slider,
      isLink,
      typewriterText,
    };
  },
};
</script>

<style scoped>
@import "../assets/css/Chatbot.css";
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");
/* Your styles here */
.error {
  color: red;
}
span {
  font-family: "Raleway", sans-serif !important;
  font-weight: 500;
}
/* Styles for selected box */
.selected-box {
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  background-color: #e5e5e5;
}

/* Additional styles for completed boxes */
.completed-box {
  color: white;
  background-color: #000000;
}

.lock-box {
  cursor: default;
}
.lock-box:hover {
  background-color: #f5f5f5;
}
</style>
