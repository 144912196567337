<template>
  <div class="w-full h-full flex flex-col justify-between">
    <ToastContainer />
    <div class="h-full flex items-center justify-center error" v-if="error">
      {{ error }}
    </div>
    <div class="w-full max-w-4xl mx-auto flex flex-col flex-grow">
      <div class="p-2 m-0 w-full rounded-lg">
        <div
          id="msg"
          ref="messagesContainerRef"
          class="enhanced-textarea overflow-y-auto pb-8"
        >
          <div
            v-for="(message, index) in messages"
            :key="index"
            :class="`mb-4 ${
              message.role === 'system' ? 'text-left' : 'text-right'
            }`"
          >
            <span
              :class="`inline-block p-3 max-w-[90%] rounded-lg ${
                message.role === 'system'
                  ? 'bg-white rounded-br-none'
                  : 'text-start bg-gray-200 rounded-bl-none'
              }`"
            >
              <pre>{{ message.content }}</pre>
            </span>
            <img
              src="../assets/translate-text.jpeg"
              class="mx-auto h-auto"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch, nextTick,onMounted } from "vue";
import { useToast, ToastContainer } from "vue-toastification";
import "vue-toastification/dist/index.css";
import apiClient from "@/services/api.js";

export default {
  name: "TranslateTextComponent",
  components: {
    // QuillEditor,
  },
  setup() {
    const messages = ref([{ role: "system", content: "" }]);
    const inputValue = ref("");
    const messagesContainerRef = ref(null);
    const textareaRef = ref(null);
    const toast = useToast();
    const isTypingEffect = ref(true);
    const loading = ref(false);
    const error = ref(null);
    const price = ref(null);
    const userInput = ref(null);
    const rentalAppraisalLetter = ref("");

    const typewriterEffect = (text, index) => {
        let charIndex = 0;
        const interval = setInterval(() => {
          if (charIndex < text.length) {
            messages.value[index].content += text.charAt(charIndex);
            charIndex++;
          } else {
            clearInterval(interval);
            isTypingEffect.value = false;
          }
        }, 40);
      };
  

    //     const QUILL_TOOLBAR = [
    //   [{ 'header': [1, 2, false] }],
    //   ['bold', 'italic', 'underline'],
    //   ['link', 'blockquote', 'code-block'],
    //   [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    //   [{ 'script': 'sub'}, { 'script': 'super' }],
    //   [{ 'indent': '-1'}, { 'indent': '+1' }],
    //   [{ 'direction': 'rtl' }],
    //   [{ 'color': [] }, { 'background': [] }],
    //   [{ 'font': [] }],
    //   [{ 'align': [] }],
    //   ['clean'],                                         // remove formatting button
    //   ['image']                                          // image button
    // ];

    const handleSend = async () => {
      if (isTypingEffect.value || loading.value) return;

      userInput.value = inputValue.value.trim();
      if (!userInput.value) {
        toast.error("Please type something");
        return;
      }

      const userMessage = { role: "user", content: userInput };
      messages.value = [...messages.value, userMessage];
      inputValue.value = "";
      isTypingEffect.value = true;

      // Show thank you message
      const thankYouMessage = {
        role: "system",
        content: "Thank you for providing the details!",
      };
      messages.value = [...messages.value, thankYouMessage];

      loading.value = true; // Start loading
      try {
        const response = await apiClient.post(`/api/v1/chatbot/chat`, {
          systemPrompt: "RENTAL_APPRAISAL_PROMPT",
          userMessage: `Address: ${userInput.value}`,
        });

        if (response.data.success) {
          price.value = response.data.data;

          // Add rental appraisal message to the messages array
          messages.value.push({
            role: "system",
            content: "Here is your rental appraisal letter:",
          });
        } else {
          throw new Error("Failed to get response");
        }
      } catch (err) {
        toast.error(err.message || "An unexpected error occurred");
      } finally {
        loading.value = false;
        isTypingEffect.value = false;
      }
    };


    function htmlToPlainText(html) {
      // Create a temporary DOM element
      const tempElement = document.createElement("div");
      tempElement.innerHTML = html;

      // Extract plain text from the DOM element
      return tempElement.innerText || tempElement.textContent;
    }

    const shareLink = () => {
      if (navigator.share) {
        const plainText = htmlToPlainText(rentalAppraisalLetter.value);

        navigator
          .share({
            title: "Rental Appraisal Letter",
            text: plainText,
          })
          .then(() => console.log("Successful share"))
          .catch((error) => console.log("Error sharing", error));
      }
    };

    const resizeTextarea = () => {
      nextTick(() => {
        const textarea = textareaRef.value;
        if (textarea) {
          textarea.style.height = "auto";
          const scrollHeight = textarea.scrollHeight;
          const lineHeight = parseInt(
            window.getComputedStyle(textarea).lineHeight,
            10
          );
          const rows = Math.floor(scrollHeight / lineHeight);
          console.log(rows);
          const maxRows = 5;
          if (rows > maxRows) {
            textarea.style.height = `${lineHeight * maxRows}px`;
            textarea.style.setProperty("overflow-y", "auto", "important");
          } else {
            textarea.style.height = `${scrollHeight}px`;
            textarea.style.overflowY = "hidden";
          }
        }
      });
    };

    const saveEdit = (key, newValue) => {
      price.value = newValue;
    };

    const scrollToBottom = () => {
      nextTick(() => {
        if (messagesContainerRef.value) {
          messagesContainerRef.value.scrollTop =
            messagesContainerRef.value.scrollHeight;
        }
      });
    };

    onMounted(() => {
        typewriterEffect("Which language would you like me to translate in?", 0);
        scrollToBottom();
      });

    watch(messages, scrollToBottom);
    watch(inputValue, resizeTextarea);

    return {
      messages,
      inputValue,
      messagesContainerRef,
      textareaRef,
      handleSend,
      ToastContainer,
      isTypingEffect,
      saveEdit,
      loading,
      error,
      shareLink,
      rentalAppraisalLetter,
      userInput,
      price,
    };
  },
};
</script>
<style scoped>
@import "../assets/css/Chatbot.css";

@font-face {
  font-family: "ABChanel Regular";
  src: url("../assets/css/abchanel-regular-webfont.ttf");
}

@font-face {
  font-family: "ABChanel Corpo Extra Light";
  src: url("../assets/css/abc-corporate-extra-light.ttf");
}

@font-face {
  font-family: "ABChanel";
  src: url("../assets/css/abchanel.ttf");
}

@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");

.error {
  color: red;
}

pre {
  white-space: pre-wrap;
  /* Since CSS 2.1 */
  white-space: -moz-pre-wrap;
  /* Mozilla, since 1999 */
  white-space: -pre-wrap;
  /* Opera 4-6 */
  white-space: -o-pre-wrap;
  /* Opera 7 */
  word-wrap: break-word;
  /* Internet Explorer 5.5+ */
  font-family: inherit !important;
  font-size: inherit !important;
}

th {
  text-align: center;
}

td {
  white-space: wrap !important;
}
ul {
  padding-left: 2rem !important;
}
li {
  list-style: disc;
}

p,
ul,
div,
ol,
li,
span,
b {
  font-family: "Raleway", sans-serif;
  font-size: 16px;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

h1 {
  font-weight: 400;
  font-family: "ABChanel Corpo Extra Light";
  font-size: 32px;
}

h2 {
  font-weight: 400;
  font-family: "ABChanel Corpo Extra Light";
  font-size: 24px;
}

h3 {
  font-weight: 400;
  font-family: "ABChanel Corpo Extra Light";
  font-size: 20px;
}

h4 {
  font-weight: 500;
  font-family: "ABChanel";
  font-size: 16px;
}
</style>
