<template>
  <div v-if="isOpen" class="modal fade show" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ title }}</h5>
          <i class="fa-solid fa-xmark" @click="hideModal"></i>
        </div>
        <div class="modal-body">
          <!-- Render the content dynamically -->
          <component :is="content" v-bind="contentProps" />
        </div>
        <!-- <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="hideModal">Close</button>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";

export default {
  name: "BaseModal",
  setup() {
    const store = useStore();
    const isOpen = computed(() => store.state.modal.isOpen);
    const title = computed(() => store.state.modal.title);
    const content = computed(() => store.state.modal.content);
    const contentProps = computed(() => store.state.modal.props);
    const hideModal = () => {
      store.dispatch("closeModal");
    };

    return { isOpen, title, content, contentProps, hideModal };
  },
};
</script>

<style scoped>
@import "~@fortawesome/fontawesome-free/css/all.css";
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease;
}
.full-size{
  width: 100%;
}
.modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
.fa-xmark {
  cursor: pointer;
}
.modal-dialog {
  max-width: 100%;
}

.modal-content {
  position: relative;
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  max-height: 80%;
  height: 100%;
  width: 100%;
  overflow-y: auto;
}

.modal-header {
  justify-content: space-between;
  padding: 5px 0;
}

.modal-body,
.modal-footer {
  padding: 20px 0;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

/* .modal-content.modal-sm {
  max-width: 300px;

}

.modal-content.modal-lg {
  max-width: 900px;

} */
</style>
