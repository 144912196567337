<template>
  <div id="app">
    <template v-if="!hideNavBar">
      <NavigationBarMarketing v-if="marketinNavVisible" />
      <NavigationBar v-else/>
    </template>
    <router-view />
    <BaseModal />
    <FooterBar />
    <LoaderComp />
  </div>
</template>

<script>
import NavigationBar from "@/components/NavigationBar.vue";
import NavigationBarMarketing from "@/components/NavigationBarMarketing.vue";
import FooterBar from "@/components/FooterBar.vue";
import BaseModal from "@/components/BaseModal.vue";
import LoaderComp from "@/components/LoaderComp.vue";
import { onMounted, ref, watchEffect } from "vue";
import { useRoute } from "vue-router";
import axios from "axios";

axios.defaults.withCredentials = true;

export default {
  name: "App",
  components: {
    NavigationBar,
    NavigationBarMarketing,
    FooterBar,
    BaseModal,
    LoaderComp,
  },
  setup() {
    const route = useRoute();
    const marketinNavVisible = ref(false);
    const hideNavBar = ref(false);

    watchEffect(() => {
      if (route.path.startsWith("/marketing")) {
        marketinNavVisible.value = true;
      } else {
        marketinNavVisible.value = false;
      }
      if (route.path.startsWith("/postlist/")) {
        hideNavBar.value = true;
      } else {
        hideNavBar.value = false;
      }
    });

    return {
      marketinNavVisible,
      hideNavBar,
    };
  },
};

onMounted(() => {
  window.scrollTo(0, 0);
});
</script>

<style>
@font-face {
  font-family: "abchanel";
  src: url("~@/assets/css/abchanel.ttf");
}
</style>
