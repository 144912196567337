<!-- <template>
  <div>
    <div v-if="isEditing">
      <textarea
        v-model="newValue"
        @blur="save"
        @input="adjustHeight"
        class="w-full p-2 border rounded-md auto-resize-textarea"
      ></textarea>
    </div>
    <div v-else>
      <span v-html="value"></span>
      <button @click="isEditing = true" class="ml-2 text-blue-500">
        <i class="fa fa-pencil"></i>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "EditableField",
  props: {
    value: {
      type: String,
      required: true,
    },
    html: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isEditing: false,
      newValue: this.value,
    };
  },
  watch: {
    value(newValue) {
      this.newValue = newValue;
    },
  },
  methods: {
    save() {
      this.isEditing = false;
      this.$emit("save", this.newValue);
    },
    adjustHeight() {
      this.$nextTick(() => {
        const textarea = this.$el.querySelector("textarea");
        if (textarea) {
          textarea.style.height = "auto";
          textarea.style.height = textarea.scrollHeight + "px";
        }
      });
    },
  },
  mounted() {
    this.adjustHeight();
  },
  updated() {
    this.adjustHeight();
  },
};
</script>

<style scoped>
textarea {
  resize: none;
}
.auto-resize-textarea {
  overflow: hidden;
}
</style> -->

<template>
    <div>
      <div v-if="isEditing">
        <div
          contenteditable="true"
          @blur="save"
          @input="updateValue"
          class="w-full p-2 border rounded-md"
          ref="editableDiv"
        ></div>
      </div>
      <div v-else>
        <span v-html="value"></span>
        <button @click="startEditing" class="ml-2">
          <i class="fa fa-pencil"></i>
        </button>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "EditableField",
    props: {
      value: {
        type: String,
        required: true,
      },
      html: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        isEditing: false,
        newValue: this.value,
      };
    },
    watch: {
      value(newValue) {
        this.newValue = newValue;
      },
    },
    methods: {
      startEditing() {
        this.isEditing = true;
        this.$nextTick(() => {
          const editableDiv = this.$refs.editableDiv;
          editableDiv.focus();
          editableDiv.innerHTML = this.newValue;
          this.placeCaretAtEnd(editableDiv);
        });
      },
      save() {
        this.isEditing = false;
        this.$emit("save", this.newValue);
      },
      updateValue(event) {
        this.newValue = event.target.innerHTML;
      },
      placeCaretAtEnd(el) {
        const range = document.createRange();
        const sel = window.getSelection();
        range.selectNodeContents(el);
        range.collapse(false);
        sel.removeAllRanges();
        sel.addRange(range);
      }
    }
  };
  </script>
  
  <style scoped>
  [contenteditable] {
    outline: none;
  }
  </style>
  
