<template>
  <!-- Navbar Start -->
  <nav class="navbar bg-white navbar-light p-0">
    <div
      class="container d-flex justify-content-between align-items-center px-4"
    >
      <!-- Added flex container -->
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasNavbar"
        aria-controls="offcanvasNavbar"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <!-- Logo -->
      <div
        class="navbar-brand d-flex align-items-center ml-0 mobile-logo cursor-pointer"
      >
        <router-link to="/">
          <img src="@/assets/logo.png" alt="Beleef" class="navbar-logo" />
        </router-link>
        <i
          class="fa fa-chevron-down text-xs text-gray-400 ml-4"
          @click="toggleDropdown"
          v-if="isToggleDropdown"
        ></i>
        <div
          v-if="isDropdownVisible"
          class="dropdown-menu show"
          @click="closeDropdown"
        >
          <router-link class="dropdown-item" to="/open-home"
            >Open Home</router-link
          >
          <router-link class="dropdown-item" to="/my-sign">My Sign</router-link>

          <router-link :to="commsLogLink" class="dropdown-item">
            Comms Log
          </router-link>
          <router-link class="dropdown-item" to="/comms-log"
            >Compliance Folder</router-link
          >
          <router-link class="dropdown-item" to="/data-reports"
            >Data Reports</router-link
          >
          <router-link class="dropdown-item" to="/negotiation"
            >Negotiation</router-link
          >
          <router-link class="dropdown-item" to="/rental-appraisal"
            >Rental Appraisal</router-link
          >
          <router-link class="dropdown-item" to="/notifications"
            >Notifications</router-link
          >
          <router-link class="dropdown-item" to="/area-insights"
            >Area Insights</router-link
          >
          <router-link class="dropdown-item" to="/toolkit">Toolkit</router-link>
        </div>
      </div>

      <div v-if="!isUserAuthenticated">
        <router-link to="/login">Sign In</router-link>
      </div>

      <div class="relative cursor-pointer">
        <img
          :src="`https://beleef-public.s3.ap-southeast-2.amazonaws.com/assets/img/${
            email.split('@')[0]
          }.jpg`"
          class="w-7 h-7 object-fill rounded-full profile"
          v-if="email"
          @click="profileDropdown"
        />
        <div v-if="isProfileDropdown" class="profile-dropdown show">
          <span class="dropdown-item">{{ display_name }}</span>
          <hr class="text-gray-200 my-0" />
          <router-link class="dropdown-item" to="/profile">Profile</router-link>
          <router-link class="dropdown-item" to="/logout">Logout</router-link>
        </div>
      </div>

      <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasNavbar">
        <div class="offcanvas-header mt-2">
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div class="offcanvas-body">
          <!-- <p class="font-semibold">How it works</p>
          <ul>
            <li>- Assess logical price</li>
            <li>- Choose Process</li>
            <li>- Communicate to the owner and buyer</li>
            <li>- Sets you free</li>
          </ul> -->
          <div>
            <p class="font-semibold cursor-pointer" @click="showHowItWorks">
              How it works
            </p>
            <ul>
              <li
                v-for="(item, index) in listItems"
                :key="index"
                v-show="visibleItems.includes(index)"
              >
                {{ item }}
              </li>
            </ul>
          </div>

          <p class="font-semibold mt-8">Community Marketing</p>

          <div
            v-if="sidebarLoading"
            class="flex items-center justify-center py-8"
          >
            <i class="fa-solid fa-spinner animate-spin text-lg"></i>
          </div>

          <div v-else>
            <div class="relative py-4">
              <input
                class="bg-neutral-100 rounded-md w-full pr-3 py-2 pl-8"
                id="username"
                type="text"
                placeholder="Search..."
                v-model="searchQuery"
                @input="debouncedFilterChats"
              />
              <div class="absolute left-0 inset-y-0 flex items-center pl-3">
                <i class="fas fa-search text-gray-400 hover:text-gray-500"></i>
              </div>
            </div>

            <div>
              <p class="text-xs text-gray-400 mt-4">Booked Appraisal</p>
              <ul class="navbar-nav">
                <li
                  v-for="chat in filteredBookedAppraisalChats"
                  :key="chat.id"
                  class="nav-item"
                >
                  <router-link
                    class="nav-link"
                    :to="`/chat/${chat.property_id}/${chat.address}?tab=book-appraisal`"
                  >
                    {{ chat.address }}
                  </router-link>
                </li>
              </ul>
            </div>

            <div>
              <p class="text-xs text-gray-400 mt-4">Appraised</p>
              <ul class="navbar-nav">
                <li
                  v-for="chat in filteredAppraisedChats"
                  :key="chat.id"
                  class="nav-item"
                >
                  <router-link
                    class="nav-link"
                    :to="`/chat/${chat.property_id}/${chat.address}?tab=book-appraisal`"
                  >
                    {{ chat.address }}
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
  <!-- Navbar End -->
</template>

<script>
import { ref, onMounted, watch, computed, nextTick } from "vue";
import { useRoute } from "vue-router";
import { Offcanvas } from "bootstrap";
import { isAuthenticated } from "@/helpers/authHelper";
import apiClient from "@/services/api.js";
import debounce from "lodash/debounce";

export default {
  name: "NavigationBar",
  setup() {
    const isUserAuthenticated = ref(isAuthenticated());
    const display_name = ref("");
    const email = ref("");
    const chats = ref([]);
    const bookedAppraisalChats = ref([]);
    const appraisedChats = ref([]);
    const searchQuery = ref("");
    const sidebarLoading = ref(false);
    const route = useRoute();
    const isDropdownVisible = ref(false);
    let bsOffcanvas = null;
    const isToggleDropdown = ref(false);
    const isProfileDropdown = ref(false);

    const checkRouteForChat = () => {
      // Updated regex pattern to include /commsLog as an optional segment
      const chatPattern = /^\/chat\/\d+\/[^/]+(\/commsLog)?$/;
      isToggleDropdown.value = chatPattern.test(route.path);
    };

    const closeOffcanvas = () => {
      const offcanvasElement = document.getElementById("offcanvasNavbar");
      bsOffcanvas = Offcanvas.getInstance(offcanvasElement);
      if (bsOffcanvas) {
        bsOffcanvas.hide();
      }
    };

    const fetchUser = async () => {
      try {
        const response = await apiClient.get(`/api/v1/users/id`);
        const { display_name: name, email: mail } = response.data;
        display_name.value = name;
        email.value = mail;
        sidebarLoading.value = false;
      } catch (error) {
        sidebarLoading.value = false;
        console.error("Error fetching user data:", error);
      }
    };

    const fetchChats = async () => {
      try {
        sidebarLoading.value = true;
        const response = await apiClient.get(`/api/v1/chat`);
        if (response.data && response.data.success) {
          chats.value = response.data.data;
          filterChats();
        }
      } catch (error) {
        sidebarLoading.value = false;
        console.error("Error fetching chats:", error);
      }
    };

    const filterChats = () => {
      bookedAppraisalChats.value = chats.value.filter(
        (chat) => chat.current_status === "Booked Appraisal"
      );
      appraisedChats.value = chats.value.filter(
        (chat) => chat.current_status === "Appraised"
      );
    };

    const filteredBookedAppraisalChats = computed(() => {
      return bookedAppraisalChats.value.filter((chat) =>
        chat.address.toLowerCase().includes(searchQuery.value.toLowerCase())
      );
    });

    const filteredAppraisedChats = computed(() => {
      return appraisedChats.value.filter((chat) =>
        chat.address.toLowerCase().includes(searchQuery.value.toLowerCase())
      );
    });

    const debouncedFilterChats = debounce(() => {
      filterChats();
    }, 300);

    const toggleDropdown = () => {
      isDropdownVisible.value = !isDropdownVisible.value;
    };

    const profileDropdown = () => {
      isProfileDropdown.value = !isProfileDropdown.value;
    };

    const handleClickOutside = (event) => {
      if (isDropdownVisible.value && !event.target.closest(".navbar-brand")) {
        isDropdownVisible.value = false;
      }
      if (isProfileDropdown.value && !event.target.closest(".profile")) {
        isProfileDropdown.value = false;
      }
    };

    const commsLogLink = computed(() => {
      const propertyId = route.params.property_id;
      const address = route.params.address;
      return `/chat/${propertyId}/${address}?tab=comms-log`;
    });

    const closeDropdown = (event) => {
      if (event.target.closest(".dropdown-item")) {
        isDropdownVisible.value = false;
      }
    };

    watch(
      () => route.path,
      () => {
        isDropdownVisible.value = false; // Close the dropdown on route change
        checkRouteForChat();
      }
    );

    onMounted(() => {
      if (isUserAuthenticated.value) {
        fetchChats();
        fetchUser();
      }
      const offcanvasElement = document.getElementById("offcanvasNavbar");
      bsOffcanvas = new Offcanvas(offcanvasElement);
      document.addEventListener("click", handleClickOutside);
      checkRouteForChat();
    });

    watch(
      () => route.path,
      async () => {
        await nextTick(); // Ensure the DOM is updated
        closeOffcanvas(); // Close the offcanvas on route change
      }
    );

    watch(route, () => {
      isUserAuthenticated.value = isAuthenticated();
      checkRouteForChat();
    });

    watch(searchQuery, () => {
      debouncedFilterChats();
    });

    const listItems = [
      "- Assess logical price",
      "- Choose Process",
      "- Communicate to the owner and buyer",
      "- Sets you free",
    ];

    const visibleItems = ref([]);

    const showHowItWorks = () => {
      visibleItems.value = []; // Reset visible items
      listItems.forEach((item, index) => {
        setTimeout(() => {
          visibleItems.value.push(index);
        }, index * 1000); // Delay each item by 1000ms
      });
    };

    return {
      listItems,
      visibleItems,
      showHowItWorks,
      isUserAuthenticated,
      display_name,
      email,
      searchQuery,
      filteredBookedAppraisalChats,
      filteredAppraisedChats,
      closeOffcanvas,
      sidebarLoading,
      debouncedFilterChats,
      isDropdownVisible,
      toggleDropdown,
      isToggleDropdown,
      isProfileDropdown,
      profileDropdown,
      commsLogLink,
      closeDropdown
    };
  },
};
</script>

<style scoped>
/* Custom CSS to style the navbar-toggler-icon */
.navbar-toggler-icon {
  border: none;
}

.custom-navbar-collapse .nav-link {
  color: #ffffff !important;
}

.custom-navbar-collapse .nav-link.active {
  color: #ffffff !important;
}

.custom-navbar-collapse .nav-link:hover {
  color: #ccc !important;
}

.custom-navbar-collapse.open {
  left: 0;
}

.navbar-toggler,
.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
  padding: 0;
  outline: none;
  border: none;
  box-shadow: none;
}

.mobile-logo .navbar-logo {
  width: 150px;
  display: block;
}

.navbar-brand {
  justify-content: center;
  margin: 0 auto;
}

.plusicon {
  font-size: 22px;
}

.fa-plus {
  font-weight: 100 !important;
}

nav {
  position: sticky;
  top: 0;
  z-index: 1000;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  display: none;
  flex-direction: column;
  padding: 0;
}

.dropdown-menu.show {
  display: flex;
}

.dropdown-item {
  font-size: 14px;
  display: block;
  width: 100%;
  padding: 0.5rem 2rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: transparent;
  border-bottom: 1px solid #e6e6e6;
}

.dropdown-item:last-child {
  border: none;
}

.dropdown-item:hover {
  background-color: #f3f4f6;
}

.dropdown-item + .dropdown-item {
  margin-top: 0;
}

.profile-dropdown {
  position: absolute;
  top: 44px;
  right: 0;
  background-color: #fff;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  border-radius: 4px;
  display: none;
  flex-direction: column;
  z-index: 1050;
  width: 250px;
  padding: 0.5rem;
  border: 1px solid #e5e7eb;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.profile-dropdown.show {
  display: flex;
}

.profile-dropdown .dropdown-item {
  font-size: 14px;
  display: block;
  width: 100%;
  padding: 0.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: left;
  white-space: nowrap;
  background-color: transparent;
  border: none !important;
}

.profile-dropdown .dropdown-item:first-child:hover {
  background-color: transparent;
}

.profile-dropdown .dropdown-item:hover {
  background-color: #f3f4f6;
}
</style>
