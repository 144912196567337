<template>
  <nav
    v-if="haveMainNaw"
    class="navbar navbar-expand-lg bg-white navbar-light sticky-top p-0"
    style="border-bottom: 1px solid #ccc"
  >
    <div class="container d-flex justify-content-between align-items-center">
      <router-link
        class="navbar-brand d-flex align-items-center ml-0 mobile-logo"
        to="/marketing"
      >
        <img src="@/assets/logo.png" alt="Beleff" class="navbar-logo" />
      </router-link>
      <button
        class="navbar-toggler d-lg-none"
        type="button"
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasNavbar"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div
        class="offcanvas offcanvas-start d-lg-none"
        tabindex="-1"
        id="offcanvasNavbar"
        data-bs-dismiss="offcanvas"
      >
        <div class="offcanvas-header">
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div class="offcanvas-body">
          <ul class="navbar-nav" style="margin-left: 20px">
            <li class="nav-item d-lg-none">
              <router-link class="nav-link" to="/marketing">Home</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/marketing/properties"
                >Properties</router-link
              >
            </li>

            <li class="nav-item">
              <router-link class="nav-link" to="/marketing/prompts">Prompts</router-link>
            </li>
            <li class="nav-item notification-link">
              <router-link class="nav-link" to="/marketing/notifications"
                >Notification
                <b v-if="store.state.notifications?.length">{{
                  store.state.notifications?.length
                }}</b></router-link
              >
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/marketing/relevant-docs"
                >Relevant document</router-link
              >
            </li>
            <li class="nav-item" v-if="!isAuthenticated()">
              <router-link class="nav-link" to="/">Sign In</router-link>
            </li>
            <li class="nav-item" v-else>
              <router-link class="nav-link" to="/logout">Sign Out</router-link>
            </li>
          </ul>
        </div>
      </div>

      <!-- Desktop Nav -->
      <div class="d-none d-lg-flex align-items-center">
        <!-- Display only on lg screens and up -->

        <ul class="navbar-nav ml-auto">
          <li class="nav-item">
            <router-link class="nav-link" to="/marketing/properties"
              >Properties</router-link
            >
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/marketing/prompts">Prompts</router-link>
          </li>
          <li class="nav-item notification-link">
            <router-link class="nav-link" to="/marketing/notifications"
              >Notification
              <b v-if="store.state.notifications?.length">{{
                store.state.notifications?.length
              }}</b></router-link
            >
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/marketing/relevant-docs"
              >Relevant document</router-link
            >
          </li>
          <li class="nav-item" v-if="!isAuthenticated()">
            <router-link class="nav-link" to="/">Sign In</router-link>
          </li>
          <li class="nav-item" v-else>
            <router-link class="nav-link" to="/logout">Sign Out</router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import { isAuthenticated } from "@/helpers/authHelper";
import { getUnreadMessages } from "@/services/marketingService";
import { onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

export default {
  name: "NavigationBarMarketing",
  setup() {
    const route = useRoute();
    const store = useStore();
    const haveMainNaw = ref(true);
    const checkIsHiddenMainNav = (path) => {
      if (path.startsWith("/marketing/draft") || path.startsWith("/marketing/completed"))
        haveMainNaw.value = false;
      else haveMainNaw.value = true;
    };
    onMounted(async () => {
      try {
        const res = await getUnreadMessages();
        store.dispatch("addNotifications", res);
      } catch (error) {
        console.error("Error fetching notification for navbar", error);
      }

      checkIsHiddenMainNav(route.path);
    });
    watch(route, () => {
      checkIsHiddenMainNav(route.path);
    });

    return {
      store,
      haveMainNaw,
      isAuthenticated,
    };
  },
};
</script>

<style scoped>
.notification-link b {
  position: relative;
  background: red;
  border-radius: 50%;
  color: white;
  min-width: 16px;
  min-height: 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 9px;
  box-sizing: border-box;
}

.navbar-toggler-icon {
  border: none;
}

.navbar-toggler,
.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
  outline: none;
  border: none;
  box-shadow: none;
}

.mobile-logo .navbar-logo {
  width: 150px;
  display: block;
}

.navbar-toggler {
  position: absolute;
}

@media (max-width: 1023px) {
  .navbar-brand {
    justify-content: center;
    margin: 0 auto;
  }

  .mobile-logo .navbar-logo {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media (min-width: 1024px) {
  .notification-mobile {
    display: none;
  }

  .navbar-brand {
    justify-content: flex-start;
    margin: 0;
  }

  .mobile-logo .navbar-logo {
    position: static;
    transform: none;
  }

  .navbar-brand h2 {
    display: block;
    right: 20px;
  }
}
</style>
