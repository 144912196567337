import { createStore } from "vuex";
const store = createStore({
  state: {
    modal: {
      isOpen: false,
      title: "",
      content: "",
    },
    loader: false,
    notifications: [],
    commentsByType: {},
  },
  mutations: {
    openModal(state, payload) {
      state.modal.isOpen = true;
      state.modal.title = payload.title;
      state.modal.content = payload.content;
      state.modal.props = payload.props;
    },
    closeModal(state) {
      state.modal.isOpen = false;
      state.modal.title = "";
      state.modal.content = "";
      state.modal.props = {};
    },
    loading(state, payload) {
      state.loader = payload;
    },
    addNotifications(state, payload) {
      state.notifications = payload;
    },
    addComments(state, payload) {
      state.commentsByType = payload;
    },
  },
  actions: {
    openModal({ commit }, payload) {
      commit("openModal", payload);
    },
    closeModal({ commit }) {
      commit("closeModal");
    },
    setLoading({ commit }, payload) {
      commit("loading", payload);
    },
    addNotifications({ commit }, payload) {
      commit("addNotifications", payload);
    },
    addComments({ commit }, payload) {
      commit("addComments", payload);
    },
  },
  getters: {
    commentsByType: (state) => {
      return state.commentsByType;
    },
  },
});

export default store;
