import { createApp } from "vue";
import Vue3Toastify from 'vue3-toastify';
import { BootstrapVue3 } from "bootstrap-vue-3";
import VueEasyLightbox from 'vue-easy-lightbox';
import App from "./App.vue";
import tooltipDirective from "./helpers/tooltip";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap-vue-3/dist/bootstrap-vue-3.css";
import "./assets/css/abc-corporate-extra-light.ttf";
import "./assets/css/style.css";
import 'vue-easy-lightbox/external-css/vue-easy-lightbox.css'
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import 'vue3-toastify/dist/index.css';
import store from "./store";
import Toast from 'vue-toastification';
import "vue-toastification/dist/index.css";

process.env.NODE_TLS_REJECT_UNAUTHORIZED = "0";
import router from "./router";
const app = createApp(App);
app.use(BootstrapVue3);
app.use(store);
app.use(router);
app.use(VueEasyLightbox);
app.use(Vue3Toastify, {
    autoClose: 3000,
  })
app.directive("tooltip", tooltipDirective);
app.use(Toast);
app.mount("#app");

//createApp(App).use(router).mount('#app');
