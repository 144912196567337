import { toast } from "vue3-toastify";
import apiClient from "./api";
// const apiUrl =process.env.VUE_APP_API_URL
const API_BASE_URL = `/api/v1/marketing`;
console.log("URL_SERVER", API_BASE_URL);
const getAllPrompts = async () => {
  try {
    const response = await apiClient.get(`${API_BASE_URL}/prompts`);
    return response.data;
  } catch (error) {
    console.error("Error fetching locations:", error);
    throw error;
  }
};

const updatePrompts = async (data) => {
  try {
    const response = await apiClient.put(
      `${API_BASE_URL}/prompts/${data.id}`,
      data
    );
    if (response.data) toast.success("Success updating prompts!");
    return response.data;
  } catch (error) {
    toast.error("Something went wrong, please try again!");
    console.error("Error uploading edited images:", error);
    throw error;
  }
};

const getLocations = async () => {
  try {
    const response = await apiClient.get(`${API_BASE_URL}/locations`);
    return response.data;
  } catch (error) {
    console.error("Error fetching locations:", error);
    throw error;
  }
};

const getLocationById = async (locationId) => {
  try {
    const response = await apiClient.get(
      `${API_BASE_URL}/location/${locationId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching locations by ID:", error);
    throw error;
  }
};

const searchLocationByAddressName = async (address) => {
  try {
    const response = await apiClient.get(
      `${API_BASE_URL}/locations/get?address=${address}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching location by address:", error);
    return error;
  }
};
const updateLocationStatus = async (id, status) => {
  try {
    const response = await apiClient.put(
      `${API_BASE_URL}/locations/status/${id}/${status}`
    );
    if (response.data) toast.success("Success updating status!");
    return response.data;
  } catch (error) {
    toast.error("Something went wrong, please try again!");
    console.error("Error updating locationstatus:", error);
    throw error;
  }
};
const getPropertyContentByLocationId = async (locationId) => {
  try {
    const response = await apiClient.get(
      `${API_BASE_URL}/property/${locationId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching property content by location ID:", error);
    throw error;
  }
};

const sendRevisions = async (data) => {
  try {
    const response = await apiClient.post(
      `${API_BASE_URL}/property/revisions`,
      data
    );
    if (response.data) toast.success("Revison sent to user!");
    return response.data;
  } catch (error) {
    console.error("Error sending raw approval:", error);
    toast.error("Something went wrong, please try again!");
    throw error;
  }
};
const uploadRawImages = async (data, address) => {
  try {
    const response = await apiClient.post(
      `${API_BASE_URL}/property/rawImage?address=${address}`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    if (response.data) toast.success("Success uploading original images!");
    return response.data;
  } catch (error) {
    toast.error("Something went wrong, please try again!");
    console.error("Error uploading raw images:", error);
    throw error;
  }
};

const uploadEditedImages = async (data, address) => {
  try {
    const response = await apiClient.post(
      `${API_BASE_URL}/property/edited?address=${address}`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    if (response.data) toast.success("Success uploading edited images!");
    return response.data;
  } catch (error) {
    toast.error("Something went wrong, please try again!");
    console.error("Error uploading edited images:", error);
    throw error;
  }
};

const uploadVideoImage = async (data, address) => {
  try {
    const response = await apiClient.post(
      `${API_BASE_URL}/property/video?address=${address}`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    if (response.data) toast.success("Success uploading video!");
    return response.data;
  } catch (error) {
    toast.error("Something went wrong, please try again!");
    console.error("Error uploading video image:", error);
    throw error;
  }
};

const uploadFloorPlanImage = async (data, address) => {
  try {
    const response = await apiClient.post(
      `${API_BASE_URL}/property/floorPlan?address=${address}`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    if (response.data) toast.success("Success uploading floorplan!");
    return response.data;
  } catch (error) {
    toast.error("Something went wrong, please try again!");
    console.error("Error uploading floor plan image:", error);
    throw error;
  }
};

const updateMultiple = async (data) => {
  try {
    const response = await apiClient.put(
      `${API_BASE_URL}/property/update/multiple`,
      data
    );
    return response.data;
  } catch (error) {
    console.error("Error updating image:", error);
    throw error;
  }
};

const updatePropertyHtml = async (data) => {
  try {
    const response = await apiClient.put(
      `${API_BASE_URL}/property/html/update`,
      data
    );
    if (response.data) toast.success("Success updating text!");
    return response.data;
  } catch (error) {
    console.error("Error updating property HTML:", error);
    throw error;
  }
};

const updateImageOrder = async (data) => {
  try {
    const response = await apiClient.put(
      `${API_BASE_URL}/property/image/changeOrder`,
      data
    );
    if (response.data) toast.success("Success updating images order!");
    return response.data;
  } catch (error) {
    console.error("Error updating image order:", error);
    throw error;
  }
};

const updateBrochureOrder = async (data) => {
  try {
    const response = await apiClient.put(
      `${API_BASE_URL}/property/image/changeOrder/brochure`,
      data
    );
    if (response.data) toast.success("Success updating brochure images order!");
    return response.data;
  } catch (error) {
    console.error("Error updating brochure image order:", error);
    throw error;
  }
};
const getAddCopyForLocation = async (locationId) => {
  try {
    const response = await apiClient.get(
      `${API_BASE_URL}/property/adcopy/${locationId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching ad copy for location:", error);
    throw error;
  }
};
const promptForAddCopy = async (id, locationId, data) => {
  try {
    const response = await apiClient.post(
      `${API_BASE_URL}/property/adcopy/prompt/${id}/${locationId}`,
      data
    );
    return response.data;
  } catch (error) {
    toast.error("Something went wrong, please try again!");
    console.error("Error ad copy for location:", error);
    throw error;
  }
};

const updateAddCopy = async (data) => {
  try {
    const response = await apiClient.put(
      `${API_BASE_URL}/property/adcopy/${data.id}`,
      data
    );
    if (response.data) toast.success("Success updating ad copy!");
    return response.data;
  } catch (error) {
    console.error("Error updating ad copy html:", error);
    throw error;
  }
};
const insertAddCopy = async (data, locationId) => {
  try {
    const response = await apiClient.post(
      `${API_BASE_URL}/property/adcopy/${locationId}`,
      data
    );
    if (response.data) toast.success("Success adding ad copy!");
    return response.data;
  } catch (error) {
    console.error("Error updating ad copy html:", error);
    throw error;
  }
};
const deleteFile = async (id, driveId) => {
  try {
    const response = await apiClient.delete(
      `${API_BASE_URL}/file/delete/${id}/${driveId}`
    );
    if (response.data) toast.success("Success deleting file!");
    return response.data;
  } catch (error) {
    console.error("Error deleting file:", error);
    throw error;
  }
};
const openAiRawImages = async (data) => {
  try {
    const response = await apiClient.post(
      `${API_BASE_URL}/property/openai/raw`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  } catch (error) {
    toast.error("Something went wrong, please try again!");
    console.error("Error updating image:", error);
    throw error;
  }
};
const openAiEditedImages = async (locationId) => {
  try {
    const response = await apiClient.get(
      `${API_BASE_URL}/property/openai/edited/${locationId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error updating image:", error);
    throw error;
  }
};
const insertAgent = async (data, locationId, folderId, address) => {
  try {
    const response = await apiClient.post(
      `${API_BASE_URL}/property/agent/${locationId}/${folderId}?address=${address}`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    if (response.data) toast.success("Success adding agent!");
    return response.data;
  } catch (error) {
    toast.error("Something went wrong, please try again!");
    console.error("Error creating agent image:", error);
    throw error;
  }
};

const insertComment = async (locationId, data) => {
  try {
    const response = await apiClient.post(
      `${API_BASE_URL}/property/comment/${locationId}`,
      data
    );
    return response.data;
  } catch (error) {
    toast.error("Something went wrong, please try again!");
    console.error("Error creating comment:", error);
    throw error;
  }
};
const getAllCommentsByLocationId = async (locationId) => {
  try {
    const response = await apiClient.get(
      `${API_BASE_URL}/property/comment/${locationId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching comment by location ID:", error);
    throw error;
  }
};
const updateCommentReadStatus = async (data) => {
  try {
    const response = await apiClient.put(
      `${API_BASE_URL}/property/comment/status`,
      data
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching locations by ID:", error);
    throw error;
  }
};
const getUnreadMessages = async () => {
  try {
    const response = await apiClient.get(
      `${API_BASE_URL}/property/comment/unread/notifications`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching unread messages:", error);
    throw error;
  }
};
const getReadMessages = async () => {
  try {
    const response = await apiClient.get(
      `${API_BASE_URL}/property/comment/read/messages`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching read messages:", error);
    throw error;
  }
};
const updateBBC = async (id, data) => {
  try {
    const response = await apiClient.put(
      `${API_BASE_URL}/property/floorPlan/bbc/${id}`,
      data
    );
    if (response.data) toast.success("Success updating BBC!");
    return response.data;
  } catch (error) {
    console.error("Error updating BBC:", error);
    throw error;
  }
};

const insertGeneralDocument = async (data, type) => {
  try {
    const response = await apiClient.post(
      `${API_BASE_URL}/general/document/${type}`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    if (response.data) toast.success("Success adding general document!");
    return response.data;
  } catch (error) {
    toast.error("Something went wrong, please try again!");
    console.error("Error creating general document:", error);
    throw error;
  }
};
const getAllGeneralDocs = async () => {
  try {
    const response = await apiClient.get(`${API_BASE_URL}/general/document`);
    return response.data;
  } catch (error) {
    console.error("Error fetching general documents:", error);
    throw error;
  }
};
const replaceGeneralDocument = async (data, fileId) => {
  try {
    const response = await apiClient.put(
      `${API_BASE_URL}/general/document/${fileId}`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    if (response.data) toast.success("Success replacing general document!");
    return response.data;
  } catch (error) {
    toast.error("Something went wrong, please try again!");
    console.error("Error creating general document:", error);
    throw error;
  }
};
const addGeneralDocToLocation = async (data) => {
  try {
    const response = await apiClient.post(
      `${API_BASE_URL}/general/document/location/add`,
      data
    );
    if (response.data) toast.success("Success adding general document!");
    return response.data;
  } catch (error) {
    toast.error("Something went wrong, please try again!");
    console.error("Error updating general document for location:", error);
    throw error;
  }
};
const getAllRelevantDocForLocation = async (locationId) => {
  try {
    const response = await apiClient.get(
      `${API_BASE_URL}/relevant/document/${locationId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching all relevant document for location:", error);
    throw error;
  }
};
const insertRelevantDocsForLocation = async (
  data,
  locationId,
  folderId,
  address,
  type
) => {
  try {
    const response = await apiClient.post(
      `${API_BASE_URL}/relevant/document/${locationId}/${folderId}/${type}?address=${address}`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    if (response.data) toast.success("Success adding relevant document!");
    return response.data;
  } catch (error) {
    toast.error("Something went wrong, please try again!");
    console.error("Error creating general document:", error);
    throw error;
  }
};
const deleteDocument = async (id, driveId) => {
  try {
    const response = await apiClient.delete(
      `${API_BASE_URL}/relevant/document/${id}/${driveId}`
    );
    if (response.data) toast.success("Success deleting relevant document!");
    return response.data;
  } catch (error) {
    console.error("Error deleting relevant document file:", error);
    throw error;
  }
};

const getOnePropertyFileId = async (id) => {
  try {
    const response = await apiClient.get(`${API_BASE_URL}/property/file/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error geting property file by id:", error);
    throw error;
  }
};
const insertTextDocType = async (data, locationId, type) => {
  try {
    const response = await apiClient.post(
      `${API_BASE_URL}/text/document/${locationId}/${type}`,
      data
    );
    if (response.data) toast.success("Success adding text!");
    return response.data;
  } catch (error) {
    console.error("Error insert Text Doc:", error);
    throw error;
  }
};
const updateTextDocType = async (data, id) => {
  try {
    const response = await apiClient.put(
      `${API_BASE_URL}/text/document/${id}`,
      data
    );
    if (response.data) toast.success("Success updating text!");
    return response.data;
  } catch (error) {
    console.error("Error insert Text Doc:", error);
    throw error;
  }
};
const updateMailCard = async (data) => {
  try {
    const response = await apiClient.post(
      `${API_BASE_URL}/property/mailcard/update`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    if (response.data) toast.success("Success updating mailCard!");
    return response.data;
  } catch (error) {
    toast.error("Something went wrong, please try again!");
    console.error("Error creating updating mailCard:", error);
    throw error;
  }
};
const getSignboardHtmlForLocation = async (locationId) => {
  try {
    const response = await apiClient.get(
      `${API_BASE_URL}/property/signboard/html/${locationId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching signboard html:", error);
    throw error;
  }
};
const updateSignboardHtml = async (data) => {
  try {
    const response = await apiClient.put(
      `${API_BASE_URL}/property/signboard/html/${data.id}`,
      data
    );
    if (response.data) toast.success("Success updating signboard text!");
    return response.data;
  } catch (error) {
    console.error("Error updating signboard html:", error);
    throw error;
  }
};
const insertSignboardHtml = async (data, locationId) => {
  try {
    const response = await apiClient.post(
      `${API_BASE_URL}/property/signboard/html/${locationId}`,
      data
    );
    if (response.data) toast.success("Success adding signboard text!");
    return response.data;
  } catch (error) {
    console.error("Error inserting signboard html:", error);
    throw error;
  }
};
export {
  getLocations,
  getLocationById,
  searchLocationByAddressName,
  getPropertyContentByLocationId,
  uploadRawImages,
  uploadEditedImages,
  uploadVideoImage,
  uploadFloorPlanImage,
  updateMultiple,
  updatePropertyHtml,
  updateImageOrder,
  getAllPrompts,
  updatePrompts,
  getAddCopyForLocation,
  updateAddCopy,
  openAiRawImages,
  deleteFile,
  sendRevisions,
  openAiEditedImages,
  insertAddCopy,
  insertAgent,
  insertComment,
  getAllCommentsByLocationId,
  updateCommentReadStatus,
  getUnreadMessages,
  getReadMessages,
  updateBBC,
  promptForAddCopy,
  insertGeneralDocument,
  getAllGeneralDocs,
  replaceGeneralDocument,
  addGeneralDocToLocation,
  getAllRelevantDocForLocation,
  insertRelevantDocsForLocation,
  deleteDocument,
  updateLocationStatus,
  getOnePropertyFileId,
  insertTextDocType,
  updateBrochureOrder,
  updateTextDocType,
  updateMailCard,
  updateSignboardHtml,
  insertSignboardHtml,
  getSignboardHtmlForLocation,
};
