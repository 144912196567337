<template>
  <div class="loader-wrapper" v-if="isLoading">
    <h1>Please wait...</h1>
    <div class="text-center">
      <b-spinner variant="primary" label="Text Centered"></b-spinner>
    </div>
  </div>
</template>
<script>
import { computed } from "vue";
import { useStore } from "vuex";

export default {
  props: {
    loading: {
      type: Boolean,
      default: () => false,
    },
  },
  setup() {
    const store = useStore();
    const isLoading = computed(() => store.state.loader);
    return {
      isLoading,
    };
  },
};
</script>

<style scoped>
.loader-wrapper {
  position: fixed;
  z-index: 9999;
  background-color: #acacac94;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  top: 0;
  flex-direction: column;
}
</style>
