<template>
  <div class="w-full h-full max-w-4xl mx-auto flex px-4 py-8 flex-col justify-between">
    <h4>Week 1</h4>
    <div v-for="(day, index) in week1" :key="index">
      <button class="accordion-button" @click="toggleDay(index)">
        Day {{ index + 1 }}
      </button>
      <div v-if="activeDay === index" class="text-start accordion-content">
        <pre>{{ day.content }}</pre>
      </div>
    </div>
    <br />
    <div class="text-start">
      <h3>Weekly Summary</h3>
      <br />
      <h4>5-Step Process: Week 1</h4>
      <br />
      <ol>
        <li>
          <h5>1. Views and Enquiries:</h5>

          <ul>
            <li>
              <p>
                <b>Initial Listing and Boosting:</b> The property was listed on
                major platforms (REA, Domain, Ausrealty) and boosted on social
                media to maximize exposure.
              </p>
            </li>
            <li>
              <p>
                <b>Enquiries Received:</b> By mid-week, 10 enquiries had been
                made, increasing to 28 by the end of the week. This strong
                initial interest indicates effective marketing and a
                well-presented property.
              </p>
            </li>
          </ul>
          <p>
            <b>Relevance:</b> The high number of enquiries in the first week is
            a positive indicator of market interest. This suggests that the
            property is priced attractively and is appealing to potential
            buyers. The continued engagement through boosting on social media
            helps maintain visibility and keeps the property in front of
            prospective buyers.
          </p>
        </li>
        <li>
          <h5>2. Inspections:</h5>
          <ul>
            <li>
              <p>
                <b>Open Home Preparation:</b> The team prepared for the open
                home by emailing all enquiries and setting up the property to be
                in the best possible state, including precautions for weather
                changes.
              </p>
            </li>
            <li>
              <p>
                <b>Inspections Held:</b> The open home saw 16 inspections,
                demonstrating a solid conversion rate from enquiry to
                inspection.
              </p>
            </li>
          </ul>
          <p>
            <b>Relevance:</b> The strong turnout for the open home shows that
            the marketing strategy is working and that potential buyers are
            interested in taking the next step to view the property in person.
            This stage is crucial for assessing buyer interest and engagement.
          </p>
        </li>
        <li>
          <h5>3. Offers:</h5>
          <ul>
            <li>
              <p>
                <b>Next Steps Post-Inspection:</b> After the open home, the plan
                was to follow up with all attendees and non-attendees to gather
                feedback and gauge interest. This step is vital for moving
                interested parties towards making offers.
              </p>
            </li>
          </ul>
          <p>
            <b>Relevance:</b> Contacting buyers after inspections helps maintain
            engagement and provides an opportunity to address any concerns,
            potentially leading to offers. By allowing buyers to reach out
            first, the team positions itself better for negotiations.
          </p>
        </li>
        <li>
          <h5>4. Close Above Feedback:</h5>
          <ul>
            <li>
              <p>
                <b>Strategic Follow-Ups:</b> The team planned to wait until
                Monday afternoon to make follow-up calls, allowing interested
                buyers time to express their interest independently, thus
                improving the negotiation position.
              </p>
            </li>
          </ul>
          <p>
            <b>Relevance:</b> Strategic follow-ups based on buyer engagement
            help in understanding market sentiment and preparing for the next
            steps in the negotiation process. This approach also helps to gauge
            genuine interest and to refine pricing strategy if needed.
          </p>
        </li>
        <li>
          <h5>5. Vendor Acceptance and Sold:</h5>
          <ul>
            <li>
              <p>
                <b>Ongoing Communication:</b> Continued communication with the
                owners has kept them informed about the process and the next
                steps, including daily updates on enquiries and strategies to
                maximize the outcome.
              </p>
            </li>
          </ul>
          <p>
            <b>Relevance:</b> Keeping the vendor informed and involved in the
            process ensures transparency and trust. This step is crucial for
            aligning expectations and making informed decisions about potential
            offers and next steps.
          </p>
        </li>
      </ol>

      <h4><b>Summary of Communication:</b></h4>
      <ul>
        <li>
          <p>The launch of the property and initial interest.</p>
        </li>
        <li>
          <p>Positive feedback on the marketing strategy.</p>
        </li>
        <li>
          <p>
            Continuous efforts to boost visibility and attract more enquiries.
          </p>
        </li>
        <li>
          <p>
            Preparations for the open home and subsequent actions based on buyer
            engagement.
          </p>
        </li>
        <li>
          <p>Strategic follow-ups planned to maximize negotiation leverage.</p>
        </li>
      </ul>
      <p>
        By clearly communicating each step and maintaining transparency, the
        team has effectively managed the campaign to keep building momentum and
        move towards securing the best possible outcome for the property.
      </p>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  name: "CommsLog",
  setup() {
    const activeDay = ref(null);

    const week1 = ref([
      {
        content: `Hi Team,
We are live! Your property looks amazing, please see the links below:

REA: https://www.realestate.com.au/145744232

Domain: https://www.domain.com.au/2019421620

Ausrealty: https://ausrealty.com.au/property/19-vines-way-catherine-field-nsw-2557/

Open home time will be this Saturday at 11.00-11:30am

We will let you know how the enquiries are going as we progress.

Have a great evening.

Thanks,
Sandy`,
      },
      {
        content: `Hi Team,

- Great enquiry overnight, excellent reaction about the marketing.
- We will update you again tonight.

Thanks,
Sandy`,
      },
      {
        content: `Hi Team,

We have boosted the property on social media today across Facebook, Instagram and LinkedIn to maximise exposure and keep building momentum to the maximum outcome.  

We will update you again this afternoon.
Have a great day.

Regards,
Sandy`,
      },
      {
        content: `Hi Team,

Quick update:

- 10 enquiries so far
- Buyers continue to comment positively on the marketing.
- We have boosted the property on social media today across Facebook, Instagram and LinkedIn to maximise exposure and keep building momentum to the maximum outcome.
- We expect the enquiry to continue to flow in over night.

Will update you again tomorrow.

Regards,
Sandy`,
      },
      {
        content: `Hi Team,

Quick update:

- 28 enquiries in total
- We have emailed all the enquiries to remind them of our open home tomorrow
- Looks like it is forecast to be a sunny day tomorrow, if it rains we will have precautions in place to keep the home in its best state by having remove shoes signs or shoe coverings.
- We will assess the response to the price range, and look to increase the range depending on:

1. Percentage of attendance vs enquiry
2. Buyer engagement, contract requests etc.
3. Pre offers.

Looking forward to our open home tomorrow between 1.00-1.30pm.
We will keep you updated.

Have a great weekend.
Sandy`,
      },
      {
        content: `Hi Team,

Recap,

-	Great turn out 16 inspections today. 
-	Home was presented beautifully

What we will do next. 


-	We will call those that inspected, but will wait until Monday 2pm before we do this. We do this to ensure that those that are interested get the opportunity to call us first. This improves our negotiation position. 
-	We will call the enquiries that did not attend and find out why. 
-	We will contact you again Monday  after we correlate all relevant information. 
-	We will be in touch as we hear any developments, even if it's Tomorrow (Sunday)
-	Everything is progressing as planned and on track to the maximum price.

Have a great weekend.
Sandy`,
      },
      {
        content: `Good Morning,

- We've continued to have enquiry over night following a great weekend open home. 
- Today we will contact all remaining buyers both who attended and who didn't attend. 
- We will be in touch later this afternoon. 

Thank you,
Sandy`,
      },
    ]);

    const toggleDay = (index) => {
      activeDay.value = activeDay.value === index ? null : index;
    };

    return {
      week1,
      activeDay,
      toggleDay,
    };
  },
};
</script>

<style scoped>
@import "../assets/css/Chatbot.css";

@font-face {
  font-family: "ABChanel Regular";
  src: url("../assets/css/abchanel-regular-webfont.ttf");
}

@font-face {
  font-family: "ABChanel Corpo Extra Light";
  src: url("../assets/css/abc-corporate-extra-light.ttf");
}

@font-face {
  font-family: "ABChanel";
  src: url("../assets/css/abchanel.ttf");
}

@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");

.error {
  color: red;
}

pre {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  font-family: inherit !important;
  font-size: inherit !important;
}

th {
  text-align: center;
}

td {
  white-space: wrap !important;
}

ul {
  padding-left: 2rem !important;
}

li {
  list-style: disc;
}

p,
ul,
div,
ol,
li,
span,
b {
  font-family: "Raleway", sans-serif;
  font-size: 16px;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

h1 {
  font-weight: 400;
  font-family: "ABChanel Corpo Extra Light";
  font-size: 32px;
}

h2 {
  font-weight: 400;
  font-family: "ABChanel Corpo Extra Light";
  font-size: 24px;
}

h3 {
  font-weight: 400;
  font-family: "ABChanel Corpo Extra Light";
  font-size: 20px;
}

h4 {
  font-weight: 500;
  font-family: "ABChanel";
  font-size: 16px;
}

.accordion-button {
  width: 100%;
  text-align: left;
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
  background-color: #f1f1f1 !important;
  margin: 1rem 0rem;
  border: none;
  outline: none;
  transition: background-color 0.3s ease;
}

.accordion-button:hover {
  background-color: #ddd;
}

.accordion-content {
  padding: 10px;
  background-color: #fafafa;
  border-bottom: 1px solid #ccc;
}
</style>
