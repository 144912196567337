<template>
  <div class="w-full h-full flex flex-col justify-between">
    <ToastContainer />
    <div class="w-full max-w-4xl mx-auto flex flex-col flex-grow">
      <div class="p-2 m-0 w-full rounded-lg">
        <div
          id="msg"
          ref="messagesContainerRef"
          class="enhanced-textarea overflow-y-auto pb-8"
        >
          <div v-if="loading" class="h-full flex items-center justify-center">
            <i class="fas fa-spinner fa-spin text-2xl"></i>
          </div>
          <div
            class="h-full flex items-center justify-center error"
            v-else-if="error"
          >
            {{ error }}
          </div>
          <div v-else-if="!isDataEmpty">
            <section class="max-w-4xl mx-auto text-center py-8 space-y-8 px-4">
              <h3>Key features of your property to maximise the outcome</h3>
              <p>
                Victorian architectural influence with ornate gingerbread trim
                and decorative gables. - Steep, multi-gabled corrugated metal
                roof. - Covered veranda with arched openings and decorative
                columns. - Detailed front door with glass panes, accessed by
                stairs. - Bay windows and small-paned windows for natural light.
                - Well-maintained lawn with a brick-bordered pathway, flower
                beds, and shrubs.
              </p>
            </section>
          </div>
          <div
            v-else
            v-for="(message, index) in messages"
            :key="index"
            :class="`mb-4 ${
              message.role === 'system' ? 'text-left' : 'text-right'
            }`"
          >
            <span
              :class="`inline-block p-3 max-w-[90%] rounded-lg ${
                message.role === 'system'
                  ? 'bg-white rounded-br-none'
                  : 'text-start bg-gray-200 rounded-bl-none'
              }`"
            >
              <div v-if="message.images && message.images.length">
                <div class="flex gap-4 overflow-x-auto mb-2">
                  <div
                    v-for="(image, imgIndex) in message.images"
                    :key="imgIndex"
                  >
                    <img :src="image" class="w-full min-w-32 max-w-40 h-auto" />
                  </div>
                </div>
                <pre>{{ message.content }}</pre>
              </div>
              <pre v-else>{{ message.content }}</pre>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="isDataEmpty && !loading"
      class="max-w-4xl mx-auto fixed z-50 bottom-2 left-0 right-0 w-full bg-white px-2 py-1"
    >
      <div class="flex flex-col items-center justify-center w-full">
        <div class="flex gap-4 mb-2">
          <div v-for="(image, index) in images" :key="index" class="relative">
            <img
              :src="image.url"
              alt="Uploaded Image"
              class="w-12 h-12 object-cover rounded-md border border-gray-100"
            />
            <button
              @click="removeImage(index)"
              class="absolute -top-2 -right-2 bg-gray-100 rounded-full w-5 h-5 p-2 flex items-center justify-center border border-gray-200 text-black"
            >
              <i class="fa-solid fa-xmark text-sm"></i>
            </button>
          </div>
        </div>

        <div class="flex gap-2 items-end justify-center w-full">
          <div v-if="isImageUpload">
            <label for="image-upload">
              <i
                class="fa-solid fa-paperclip text-xl text-gray-500 cursor-pointer hover:text-black mb-1"
              ></i>
            </label>
            <input
              id="image-upload"
              type="file"
              accept=".png,.jpg,.jpeg,.webp,.gif"
              class="hidden"
              @change="handleImageUpload"
              multiple
            />
          </div>

          <textarea
            v-model="inputValue"
            @keypress.enter.prevent="handleSend"
            @input="resizeTextarea"
            placeholder="Type here..."
            class="flex-grow p-2 bg-white border border-gray-400 rounded-md outline-none focus:outline-none resize-none overflow-y-hidden enhanced-textarea"
            style="height: 34px"
            rows="1"
            ref="textareaRef"
          />
          <button
            @click="handleSend"
            class="bg-black text-white rounded-md px-4 py-2"
            :disabled="loading"
          >
            <i class="fa-solid fa-location-arrow"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, nextTick, watch, onMounted } from "vue";
import { useToast, ToastContainer } from "vue-toastification";
import "vue-toastification/dist/index.css";

export default {
  name: "PostListComponent",
  setup() {
    const inputValue = ref("");
    const messages = ref([]);
    const messagesContainerRef = ref(null);
    const textareaRef = ref(null);
    const loading = ref(false);
    const error = ref(null);
    const isImageUpload = ref(true);
    const images = ref([]);
    const isDataEmpty = ref(true);
    const isTypingEffect = ref(false);

    const typewriterEffect = (text, index) => {
      isTypingEffect.value = true;
      let charIndex = 0; // Start from 0 to begin from the first character
      const interval = setInterval(() => {
        if (charIndex < text.length) {
          messages.value[index].content += text.charAt(charIndex);
          charIndex++;
        } else {
          clearInterval(interval);
          isTypingEffect.value = false;
        }
      }, 40);
    };

    const handleImageUpload = (event) => {
      const files = event.target.files;

      if (images.value.length + files.length > 3) {
        useToast().error("You can only upload up to 3 images.");
        return;
      }

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const reader = new FileReader();
        reader.onload = (e) => {
          images.value.push({ file, url: e.target.result });
        };
        reader.readAsDataURL(file);
      }

      event.target.value = ""; // Reset the file input value to allow re-uploading the same file
    };

    const removeImage = (index) => {
      images.value.splice(index, 1);
    };

    const resizeTextarea = () => {
      nextTick(() => {
        const textarea = textareaRef.value;
        if (textarea) {
          textarea.style.height = "auto";
          const scrollHeight = textarea.scrollHeight;
          textarea.style.height = `${scrollHeight}px`;
        }
      });
    };

    watch(messages, () => {
      nextTick(() => {
        if (messagesContainerRef.value) {
          messagesContainerRef.value.scrollTop =
            messagesContainerRef.value.scrollHeight;
        }
      });
    });

    onMounted(() => {
      const systemMessage = {
        role: "system",
        content: "",
      };
      messages.value.push(systemMessage);

      // Typewriter effect for the system message
      typewriterEffect(
        "What are the key features of the property? upload upto 3 images or write the description",
        messages.value.length - 1
      );
    });

    const handleSend = () => {
      // Prevent sending if already loading or the typewriter effect is active
      if (loading.value || isTypingEffect.value) return;

      const userInput = inputValue.value.trim();

      // Validate if either userInput or images are provided
      if (!userInput && images.value.length === 0) {
        useToast().error("Please type something or upload an image");
        return;
      }

      // Prepare the message object
      const userMessage = {
        role: "user",
        content: userInput,
        images: images.value.map((image) => image.url),
      };

      // Add the user's message to the messages array
      messages.value.push(userMessage);

      // Reset the input fields
      inputValue.value = "";
      images.value = [];
      isDataEmpty.value = false;

      // Show loading spinner and delay response
      loading.value = true;
      setTimeout(() => {
        const systemMessage = {
          role: "system",
          content: "",
        };
        messages.value.push(systemMessage);

        // Typewriter effect for the system message
        typewriterEffect(
          "What are the key features of the property? upload upto 3 images or write the description",
          messages.value.length - 1
        );

        loading.value = false;
      }, 5000);
    };

    return {
      inputValue,
      messages,
      messagesContainerRef,
      textareaRef,
      ToastContainer,
      loading,
      error,
      isImageUpload,
      images,
      handleImageUpload,
      removeImage,
      resizeTextarea,
      isDataEmpty,
      isTypingEffect,
      handleSend,
    };
  },
};
</script>

<style scoped>
/* Add your custom styles here */
</style>
