import Cookies from 'js-cookie';


const TOKEN_NAME = "token";

const isAuthenticated = () => !!Cookies.get(TOKEN_NAME);

const clearAuthentication = () => {
    // If using localStorage or sessionStorage for any other auth info:
    // localStorage.removeItem('someKey');
    // sessionStorage.removeItem('someKey');
  
    // Clear authentication cookies
    document.cookie = `${TOKEN_NAME}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    document.cookie = "refreshToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  
    // Additional cleanup actions, if any, can go here.
}

const token = ()=>{
    if(Cookies.get(TOKEN_NAME)){
        return Cookies.get(TOKEN_NAME);
    }
}



export {
    isAuthenticated,
    clearAuthentication,
    token
};
