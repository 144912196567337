import { Tooltip } from 'bootstrap';

export default {
  beforeMount(el, binding) {
    let placement = 'top'; // Default placement
    if (binding.arg) {
      placement = binding.arg;
    }
    new Tooltip(el, {
      title: binding.value,
      placement: placement,
      trigger: 'hover',
      container: 'body'
    });
  },
};
