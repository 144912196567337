import axios from 'axios';
import Cookies from 'js-cookie';
import router from '@/router/index.js'; // import your router instance
import { clearAuthentication } from '@/helpers/authHelper';

const apiClient = axios.create();
const TOKEN_NAME = "token";

apiClient.interceptors.request.use(
  (config) => {
    const token = Cookies.get(TOKEN_NAME);
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
 
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

apiClient.interceptors.response.use(
  response => response,
  error => {
    if (error.response) {
      if(error.response.status === 403){
        router.push('/login');
      }
      if(error.response.status === 401){
        router.push('/login?msg=Session expired, please login');
        clearAuthentication()
      }
      return;
    }
    return Promise.reject(error);
  }
);



export default apiClient;
